main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: calc(10px + 2vmin);
    color: white;
}

main img {
    margin-top: 20px;
    margin-bottom: 0;
    max-width: 70px;
    max-height: 70px;
}

main li {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.listItem {
    display: flex;
    justify-content: space-between;
    width: 80vw;
    max-width: 600px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 10px;
}

h1 {
    font-weight: 900;
    font-size: 1rem;
    color: #6e6b76;
}

@media (max-width: 600px) {
    .listItem {
        width: 90vw;
    }
}

.listItem a:first-child {
    display: flex;
    flex-direction: column;
}

a {
    color: #fff;
    text-decoration: none;
}

.fileName {
    transition: 0.3s all;
    border-bottom: 1px solid transparent;
    font-weight: 200;
}

.fileName:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.fileData {
    font-size: 1rem;
}

ul {
    list-style: none;
}

svg {
    pointer-events: none;
}

.custom-file-upload:hover {
    border-radius: 100px;
}

.delBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    background-color: transparent;
    border-radius: 300px;
    color: #fff;
    transition: 0.3s all;
    height: 44px;
    width: 44px;
}

.delBtn:hover {
    cursor: pointer;
    transform: rotate(90deg)
}

.downloadBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    height: 44px;
    width: 44px;
}

.downloadBtn:hover {
    cursor: pointer;
    transform: scale(1.1)
}

.xButtons {
    display: flex;
}

.allItems ul {
    margin-top: 10vh;
    padding: 0;
}

.allItems ul li:first-child {
    margin: 0;
}

/*################################################
* DropZone
################################################*/
.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    width: 200px;
    padding: 20px;
    margin-top: 40px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid white;
}
.postDrop:after {
    content: '';
    height: 225px;
    width: 225px;
    position: absolute;
    border: dashed 4px #7024ff;
    border-radius: 16px;
}

/*################################################
* Preloader
################################################*/

#preloader {
    position: absolute;
    top: 250px;
    left: 0;
    width: 100%;
    height: 100%;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}